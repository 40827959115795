export default {
  init() {
    // JavaScript to be fired on all pages

    $(document).ready(function(){
      var altura =  400;
      
      $(window).on('scroll', function(){
        if ( $(window).scrollTop() > altura ){
          $('.banner').addClass('menu-fixed');
        } else if ( $(window).scrollTop() < altura - 40 ){
          $('.banner').removeClass('menu-fixed');
        }
      });

      $('#mainnavigation a').on('click', function(){
        $('#mainnavigation').removeClass('show');
    });
    
    });
    
    $('.slider.slider-testimonios .slider-inner').slick({
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover:true,
      slidesToShow: 1,
      dots: true,
      infinite: false,
      speed: 1500,
      variableWidth: false,
      swipeToSlide: true,
      adaptiveHeight:true,
      cssEase: 'ease-in',
      prevArrow: '.slider-testimonios .slider-control .slider-prev',
      nextArrow: '.slider-testimonios .slider-control .slider-next',
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            autoplay: false,
            variableWidth: true,
            adaptiveHeight: true,
          },
        },
      ],
    });





  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
